import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>"2024 marks a pivotal year in my journey, focusing on cutting-edge web app development, Python-driven TradingView insights, and bespoke logo design. Engage with top-tier social media strategies, SEO excellence, and eye-catching digital posters. Dive deep into crypto trends with BTC, ETH, SOL, and more, alongside innovative ERC20, BEP20, and SOL20 token deployments. Stay ahead in commodity markets with precise analysis and CNC programming prowess." <h3><sub>read</sub><br></br>.....MORE.....<br></br><sup>click below tab</sup><a href="https://raffiofficial.netlify.app">🖱️<sup>marketcalc</sup></a></h3></p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <p>🚀 Exciting developments are underway across a spectrum of projects! Currently, I'm immersed in cutting-edge web application development, utilizing Python on the TradingView platform for live trading insights. My expertise extends to custom logo design, social media marketing strategies, and SEO optimization, ensuring brands shine in the digital sphere. Engaging digital wall posters and lead-generating research initiatives are also part of my dynamic portfolio.</p>
                      <h6>🔍💻📈 #Web application development #Python programming #TradingView platform #Logo design #Social media marketing #SEO optimization #Digital wall posters #Lead generation</h6>
                      <br></br>
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>In the crypto realm, I'm deeply involved in technical research on major tokens like BTC, ETH, SOL, and more, alongside pioneering projects like Pi Network. I specialize in developing and deploying ERC20, BEP20, and SOL20 tokens, driving innovation in decentralized finance. Additionally, my focus spans commodity research on Gold, Silver, Crude Oil, Natural Gas, and Copper, exploring market dynamics and trends.</p>
                        <h6>🔍💻📈 #Crypto, BTC ETH, SOL, BNB, DOT, Vchain, ARB, ICP #ERC20 tokens #BEP20 tokens #SOL20 tokens #Commodity research #Gold market analysis #Silver price trends #Crude oil forecasts #Natural gas market updates #Copper market trends</h6>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>With a commitment to continuous learning and updating my CNC programming skills, I'm dedicated to delivering forward-thinking solutions tailored to client needs. Explore the future of tech and finance with me as I navigate these exciting projects toward success!</p>
                      <h6>🔍💻📈 #WebDevelopment #CryptoResearch #DigitalMarketing #CommodityAnalysis</h6>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
